@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "ethno";
  src: url("./font/ethnocentric.otf");
  font-style: normal;
  font-display: swap;
  unicode-range: "U+0180-U+024F";
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #434343;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #202020;
  border-radius: 5px;
}

.inn-shadow {
  -moz-box-shadow: inset 0 0 50px 22px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0 0 50px 22px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 50px 22px rgba(0, 0, 0, 0.5);
}

.inn-shadow-min {
  -moz-box-shadow: inset 0 0 25px 10px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0 0 25px 10px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 25px 10px rgba(0, 0, 0, 0.5);
}
